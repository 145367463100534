:root {
  --Oblue: #0066ff;
  --OlightBlue: #1a8efd;
  --OgrayText: #555555;
  --OLightGray: #919191;
}

.OPlan_Group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.OPlan_Group p, .OPlan_Group span {
  font-size: 1em;
  color: black;
}

.OPlanPrice {
  height: 70px;
  text-align: center;
}

.OPlanContainer {
  padding: 0 10px;
  margin: 10px 0;
}

.OPlanContainer, .OPlanContainer * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Helvetica;
}

.OPlanPrice * {
  display: inline-block;
}

.OCurrency {
  font-size: 0.8em !important;
  vertical-align: top;
  line-height: 60px;
  margin-right: 5px;
  color: var(--OLightGray);
  direction: ltr;
}

.OPlanDuration {
  color: var(--OLightGray);
  vertical-align: middle;
  font-size: 1.3rem !important;
}

.OGuarantee {
  text-align: center;
  font-size: 0.7em !important;
  margin-top: 5px;
  color: var(--OgrayText);
}

.OPrice {
  font-size: 2em !important;
  font-weight: bold;
  direction: ltr;
  line-height: 70px;
}

.OSelect {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.OSelect a {
  margin: auto;
  text-decoration: none;
  cursor: pointer;
  padding: 7px 35px;
  background-color: var(--OlightBlue);
  color: white;
  border: 0;
  border-radius: 5px;
  transition: all 0.2s ease;
}

.OSelect button:hover {
  background-color: var(--Oblue);
}

.OPlan {
  flex: 1 0 25%;
  min-width: 315px;
  height: 610px;
  margin: 5px;
  box-shadow: 3px 3px 3px 3px #dddddd;
  max-width: 315px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  background-color: white;
  transition: all 0.1s ease;
}

.OChoices {
  padding: 10px;
}

.OChoices p {
  font-size: 0.85em;
  float: left;
  width: calc(100% - 35px);
}

.OChoiceGroup {
  display: block;
  overflow: hidden;
  padding: 5px 0;
}

.OChoiceGroup p {
  text-align: left;
}

.OChoiceGroup svg {
  margin-right: 5px;
  font-size: 1em;
  width: 30px !important;
  color: var(--OlightBlue);
  height: 1em;
  float: left;
}

.OPlanHeader {
  height: 50px;
  background-color: var(--OlightBlue);
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  justify-content: center;
}

.OHighlight {
  width: 150px;
  height: 30px;
  position: absolute;
  background-color: white;
  top: 20px;
  right: -40px;
  transform: rotate(45deg);
}

.OHighlightText {
  text-align: center;
  line-height: 30px;
  color: var(--OgrayText) !important;
  font-size: 0.8rem !important;
}

.OPlanHeader p:nth-of-type(1) {
  color: #ececec;
  font-size: 1.3rem;
}

.OPlanHeader p:nth-of-type(2) {
  color: #ececec;
  font-size: 0.85rem;
}

.btn1 {
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 30px;
  border: none;
  letter-spacing: .02rem;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.6);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.6);
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  position: relative;
  top: 0;
}

